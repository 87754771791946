import React from "react";

import HTMLReactParser from "html-react-parser";

import { CardsWithGalleryContainer, Card } from "./CardsWithoutGallery.styles";

const CardsWithoutGallery = (props) => {
  //PROPS
  const { contacts, numberOfColumns, helloText } = props;
  //PROPS

  //DEFS
  let width = `${numberOfColumns * 236}`;
  //DEFS

  return (
    <CardsWithGalleryContainer dataWidth={width}>
      <div className="contacts-wrapper">
        {contacts.map((item, index) => {
          return (
            <Card key={index}>
              <a href={item.link}>
                <h4>{item.title}</h4>
                <p>{item.description && HTMLReactParser(item.description)}</p>
              </a>
            </Card>
          );
        })}
      </div>
      {helloText && HTMLReactParser(helloText)}
    </CardsWithGalleryContainer>
  );
};

export default CardsWithoutGallery;
