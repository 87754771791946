import React from "react";

import HTMLReactParser from "html-react-parser";
import { graphql } from "gatsby";

import GoogleMaps from "components/googleMaps";
import CardsWithoutGallery from "components/cardsWithoutGallery";

const ContactsTemplate = (props) => {
  //PROPS
  const {
    data: {
      page: {
        contacts_page: {
          introText,
          contacts,
          numberOfColumns,
          latitude,
          longitude,
          address,
          helloText
        }
      }
    },
    locale
  } = props;
  //PROPS

  return (
    <>
      <main className="main main--flex">
        <h1 className="abs_hide">
          {locale === "EN" ? "Contacts" : "Contactos"}
        </h1>
        <div className="container container--small container--center contact-text">
          {introText && HTMLReactParser(introText)}
          <CardsWithoutGallery
            contacts={contacts}
            helloText={helloText}
            numberOfColumns={numberOfColumns}
          />
        </div>

        <GoogleMaps latitude={latitude} longitude={longitude} />

        <div className="container container--small container--center contact-text">
          {address && HTMLReactParser(address)}
        </div>
      </main>
    </>
  );
};

export const query = graphql`
  query Contacts($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
      seo {
        seoDescription
        seoTitle
        seoImage {
          localFile {
            url
          }
        }
      }
      contacts_page {
        address
        numberOfColumns
        introText
        helloText
        latitude
        longitude
        contacts {
          link
          title
          description
        }
      }
    }
    allWpSkill {
      edges {
        node {
          slug
          name
          id
          language {
            code
          }
        }
      }
    }
  }
`;

export default ContactsTemplate;
