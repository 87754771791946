import styled from "styled-components";

import { toRem } from "utils/mixins";
import { breakpoints } from "utils/breakpoints";

export const CardsWithGalleryContainer = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: ${toRem(46)};
  .contacts-wrapper {
    width: 100%;
    display: flex;
    margin: ${toRem(23)} auto;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  }
  @media ${breakpoints.forBigTablet} {
    .contacts-wrapper {
      width: ${(props) => props.dataWidth + "px"};
    }
  }
`;

export const Card = styled.div`
  width: ${toRem(216)};
  margin: ${toRem(23)} ${toRem(10)};
  text-align: center;
  transition: 400ms;
  &:hover {
    opacity: 0.2;
  }
  h4 {
    margin-bottom: 6px;
  }
  p {
    text-align: center;
    font-size: 1.4rem;
  }
  @media ${breakpoints.forTablet} {
    width: ${(props) => props.dataWidth};
  }
`;
