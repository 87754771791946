import styled from "styled-components";
import { toRem } from "utils/mixins";

export const MapContainer = styled.div`
  width: 100%;
  height: ${toRem(440)};
  margin-bottom: ${toRem(15)};
  * {
    max-height: ${toRem(440)};
  }
`;
