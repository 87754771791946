import React from "react";

import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

import { MapContainer } from "./GoogleMaps.styles";

const GoogleMaps = (props) => {
  //PROPS
  const { latitude, longitude, google } = props;
  //PROPS

  //DEFS
  const style = {
    width: window.innerWidth < 600 ? "100%" : "calc(100% - 32px)",
    height: "100%",
    margin: window.innerWidth < 600 ? "0" : "0 16px"
  };

  const mapStyle = [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5"
        }
      ]
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161"
        }
      ]
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#f5f5f5"
        }
      ]
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#bdbdbd"
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee"
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575"
        }
      ]
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5"
        }
      ]
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e"
        }
      ]
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff"
        }
      ]
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#dadada"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161"
        }
      ]
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e"
        }
      ]
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5"
        }
      ]
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#c9c9c9"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e"
        }
      ]
    }
  ];
  //DEFS

  return (
    <MapContainer>
      <Map
        styles={mapStyle}
        google={google}
        style={style}
        initialCenter={{
          lat: Number(latitude),
          lng: Number(longitude)
        }}
        zoom={15}
      >
        <Marker
          name={"We're here"}
          position={{ lat: Number(latitude), lng: Number(longitude) }}
          icon={{
            url: "/images/marker.svg",
            anchor: new google.maps.Point(50, 50),
            scaledSize: new google.maps.Size(100, 100)
          }}
        />
      </Map>
      {/* <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12451.873509950516!2d-9.123382!3d38.718535!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x21e5e2803c0a7a66!2sPlayground!5e0!3m2!1spt-PT!2spt!4v1616404490868!5m2!1spt-PT!2spt"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
      ></iframe> */}
    </MapContainer>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCnYqH87jqT63C1XCk6_QA4zx1gdiylaWY"
})(GoogleMaps);
